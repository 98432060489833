body {
    font-family: 'Times New Roman', serif;
    margin: 0;
    padding: 0;
    background-color:  #f0f3ff;
    color: #333;
}

header, footer {
    background-color:  #f0f3ff;
    padding: 0.5rem;
    text-align: center;
}

header h1 {
    margin: 0;
}

nav ul {
    list-style: none;
    padding: 0;
}

nav ul li {
    display: inline;
    margin-right: 20px;
}

nav ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

article {
    max-width: 800px;
    margin: 20px auto;
    background-color: #f0f3ff;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

article header h2 {
    margin-top: 0;
}

.byline {
    color: #666;
    font-style: italic;
}

footer p {
    margin: 0;
}

main {
    padding: 20px 0;
}

@media (max-width: 600px) {
    nav ul li {
        display: block;
        margin-bottom: 10px;
    }
}
